:root {
  --back-white: rgba(253, 253, 253, 1);
  --backwhite: rgba(253, 253, 253, 1);
  --black: rgba(39, 39, 39, 1);
  --bold-font-family: "Noto Sans JP", Helvetica;
  --bold-font-size: 14px;
  --bold-font-style: normal;
  --bold-font-weight: 700;
  --bold-letter-spacing: 0.7px;
  --bold-line-height: 25px;
  --bold-SP-font-family: "Noto Sans JP", Helvetica;
  --bold-SP-font-size: 15px;
  --bold-SP-font-style: normal;
  --bold-SP-font-weight: 700;
  --bold-SP-letter-spacing: 0.75px;
  --bold-SP-line-height: normal;
  --button-SP-font-family: "Noto Sans JP", Helvetica;
  --button-SP-font-size: 12px;
  --button-SP-font-style: normal;
  --button-SP-font-weight: 700;
  --button-SP-letter-spacing: 0.96px;
  --button-SP-line-height: normal;
  --dark-blue: rgba(43, 57, 135, 1);
  --darkgray: rgba(69, 69, 69, 1);
  --date-font-family: "Manrope", Helvetica;
  --date-font-size: 13.050000190734863px;
  --date-font-style: normal;
  --date-font-weight: 400;
  --date-letter-spacing: 1.3050000190734863px;
  --date-line-height: normal;
  --gray: rgba(138, 138, 138, 1);
  --lead-font-family: "Noto Sans JP", Helvetica;
  --lead-font-size: 16px;
  --lead-font-style: normal;
  --lead-font-weight: 400;
  --lead-letter-spacing: 0.32px;
  --lead-line-height: 28px;
  --lead-SP-font-family: "Noto Sans JP", Helvetica;
  --lead-SP-font-size: 12px;
  --lead-SP-font-style: normal;
  --lead-SP-font-weight: 400;
  --lead-SP-letter-spacing: 0.24px;
  --lead-SP-line-height: 24px;
  --lightpurple: rgba(181, 194, 222, 1);
  --linecolor: rgba(217, 217, 217, 1);
  --palegray: rgba(242, 242, 242, 1);
  --palepurple: rgba(239, 241, 248, 1);
  --pink: rgba(234, 166, 225, 1);
  --purple: rgba(96, 121, 193, 1);
  --red: rgba(244, 73, 82, 1);
  --smalltext-SP-font-family: "Noto Sans JP", Helvetica;
  --smalltext-SP-font-size: 10px;
  --smalltext-SP-font-style: normal;
  --smalltext-SP-font-weight: 400;
  --smalltext-SP-letter-spacing: 0.2px;
  --smalltext-SP-line-height: 18px;
  --smalltextbold-SP-font-family: "Noto Sans JP", Helvetica;
  --smalltextbold-SP-font-size: 12px;
  --smalltextbold-SP-font-style: normal;
  --smalltextbold-SP-font-weight: 700;
  --smalltextbold-SP-letter-spacing: 0.6px;
  --smalltextbold-SP-line-height: 21px;
  --tag-font-family: "Noto Sans JP", Helvetica;
  --tag-font-size: 13px;
  --tag-font-style: normal;
  --tag-font-weight: 500;
  --tag-letter-spacing: 0.65px;
  --tag-line-height: 14.800000190734863px;
  --text-bold-font-family: "Noto Sans JP", Helvetica;
  --text-bold-font-size: 15px;
  --text-bold-font-style: normal;
  --text-bold-font-weight: 700;
  --text-bold-letter-spacing: 0.75px;
  --text-bold-line-height: 26px;
  --text-bold-mini-font-family: "Noto Sans JP", Helvetica;
  --text-bold-mini-font-size: 12px;
  --text-bold-mini-font-style: normal;
  --text-bold-mini-font-weight: 700;
  --text-bold-mini-letter-spacing: 0.6px;
  --text-bold-mini-line-height: 20px;
  --text-font-family: "Noto Sans JP", Helvetica;
  --text-font-size: 16px;
  --text-font-style: normal;
  --text-font-weight: 400;
  --text-letter-spacing: 0.48px;
  --text-line-height: 31px;
  --text-mini-font-family: "Noto Sans JP", Helvetica;
  --text-mini-font-size: 12px;
  --text-mini-font-style: normal;
  --text-mini-font-weight: 400;
  --text-mini-letter-spacing: 0.6px;
  --text-mini-line-height: 20px;
  --text-SP-font-family: "Noto Sans JP", Helvetica;
  --text-SP-font-size: 13px;
  --text-SP-font-style: normal;
  --text-SP-font-weight: 400;
  --text-SP-letter-spacing: 0.13px;
  --text-SP-line-height: 23px;
  --textbold-SP-font-family: "Noto Sans JP", Helvetica;
  --textbold-SP-font-size: 13px;
  --textbold-SP-font-style: normal;
  --textbold-SP-font-weight: 700;
  --textbold-SP-letter-spacing: 0.13px;
  --textbold-SP-line-height: 23px;
  --white: rgba(255, 255, 255, 1);
}
