:root {
  --back-white: #fdfdfd;
  --backwhite: #fdfdfd;
  --black: #272727;
  --bold-font-family: "Noto Sans JP", Helvetica;
  --bold-font-size: 14px;
  --bold-font-style: normal;
  --bold-font-weight: 700;
  --bold-letter-spacing: .7px;
  --bold-line-height: 25px;
  --bold-SP-font-family: "Noto Sans JP", Helvetica;
  --bold-SP-font-size: 15px;
  --bold-SP-font-style: normal;
  --bold-SP-font-weight: 700;
  --bold-SP-letter-spacing: .75px;
  --bold-SP-line-height: normal;
  --button-SP-font-family: "Noto Sans JP", Helvetica;
  --button-SP-font-size: 12px;
  --button-SP-font-style: normal;
  --button-SP-font-weight: 700;
  --button-SP-letter-spacing: .96px;
  --button-SP-line-height: normal;
  --dark-blue: #2b3987;
  --darkgray: #454545;
  --date-font-family: "Manrope", Helvetica;
  --date-font-size: 13.05px;
  --date-font-style: normal;
  --date-font-weight: 400;
  --date-letter-spacing: 1.305px;
  --date-line-height: normal;
  --gray: #8a8a8a;
  --lead-font-family: "Noto Sans JP", Helvetica;
  --lead-font-size: 16px;
  --lead-font-style: normal;
  --lead-font-weight: 400;
  --lead-letter-spacing: .32px;
  --lead-line-height: 28px;
  --lead-SP-font-family: "Noto Sans JP", Helvetica;
  --lead-SP-font-size: 12px;
  --lead-SP-font-style: normal;
  --lead-SP-font-weight: 400;
  --lead-SP-letter-spacing: .24px;
  --lead-SP-line-height: 24px;
  --lightpurple: #b5c2de;
  --linecolor: #d9d9d9;
  --palegray: #f2f2f2;
  --palepurple: #eff1f8;
  --pink: #eaa6e1;
  --purple: #6079c1;
  --red: #f44952;
  --smalltext-SP-font-family: "Noto Sans JP", Helvetica;
  --smalltext-SP-font-size: 10px;
  --smalltext-SP-font-style: normal;
  --smalltext-SP-font-weight: 400;
  --smalltext-SP-letter-spacing: .2px;
  --smalltext-SP-line-height: 18px;
  --smalltextbold-SP-font-family: "Noto Sans JP", Helvetica;
  --smalltextbold-SP-font-size: 12px;
  --smalltextbold-SP-font-style: normal;
  --smalltextbold-SP-font-weight: 700;
  --smalltextbold-SP-letter-spacing: .6px;
  --smalltextbold-SP-line-height: 21px;
  --tag-font-family: "Noto Sans JP", Helvetica;
  --tag-font-size: 13px;
  --tag-font-style: normal;
  --tag-font-weight: 500;
  --tag-letter-spacing: .65px;
  --tag-line-height: 14.8px;
  --text-bold-font-family: "Noto Sans JP", Helvetica;
  --text-bold-font-size: 15px;
  --text-bold-font-style: normal;
  --text-bold-font-weight: 700;
  --text-bold-letter-spacing: .75px;
  --text-bold-line-height: 26px;
  --text-bold-mini-font-family: "Noto Sans JP", Helvetica;
  --text-bold-mini-font-size: 12px;
  --text-bold-mini-font-style: normal;
  --text-bold-mini-font-weight: 700;
  --text-bold-mini-letter-spacing: .6px;
  --text-bold-mini-line-height: 20px;
  --text-font-family: "Noto Sans JP", Helvetica;
  --text-font-size: 16px;
  --text-font-style: normal;
  --text-font-weight: 400;
  --text-letter-spacing: .48px;
  --text-line-height: 31px;
  --text-mini-font-family: "Noto Sans JP", Helvetica;
  --text-mini-font-size: 12px;
  --text-mini-font-style: normal;
  --text-mini-font-weight: 400;
  --text-mini-letter-spacing: .6px;
  --text-mini-line-height: 20px;
  --text-SP-font-family: "Noto Sans JP", Helvetica;
  --text-SP-font-size: 13px;
  --text-SP-font-style: normal;
  --text-SP-font-weight: 400;
  --text-SP-letter-spacing: .13px;
  --text-SP-line-height: 23px;
  --textbold-SP-font-family: "Noto Sans JP", Helvetica;
  --textbold-SP-font-size: 13px;
  --textbold-SP-font-style: normal;
  --textbold-SP-font-weight: 700;
  --textbold-SP-letter-spacing: .13px;
  --textbold-SP-line-height: 23px;
  --white: #fff;
}
/*# sourceMappingURL=index.7f8492dc.css.map */
